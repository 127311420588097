.display {
    width: 997px;
    height: 1092px;
    border: 1px solid #E3E3E3;
}

.display img {
    position: relative;
    height: 570px;
    width: 880px;
    top: 180px;
    left: 27px;
}