.panel {
    display: flex;
    position: fixed;
    top: 4.5vh;
    right: 1vw;
    flex-direction: column;
    background-color: white;
    width: 22vw;
    height: 92vh;
    z-index: 500;
    border: 1px solid #E3E3E3;
}