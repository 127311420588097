.viewer {
    background-color: white;
    width: 95vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.entrypanelpanorama3D{
    width: 10vw;
    height: 8vh;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-left: 100px;
    margin-right: 100px;
}

.navlinkoutline {
    border: 2px solid black;
    display: flex;
    flex-direction: column;
}

.navlinkoutline2 {
    border: 2px solid black;
    display: flex;
    flex-direction: column;
}

.navlinkoutline:hover {
    background-color: black;
    color: white;
    box-shadow: 10px 10px rgb(70, 201, 157);
}


.navlinkoutline2:hover {
    background-color: black;
    color: white;
    box-shadow: 10px 10px rgb(226, 223, 51);
}

/* .entryborder {
    padding: 10vh;
    background-color: white;
} */

