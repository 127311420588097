body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  background-color: #F7FBFF;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hidden {
  visibility: hidden;
}

.visible{
  visibility: visible;
}

.canvas {
  width: 65vw;
}

/* Edit button */
.edit-button button {
  padding: 4px 8px; 
  position: fixed;
  display: inline-block;
  right: 55px;
  top: 55px;
}

/* Offcanvas start */
.sidebar-button {
  padding: 4px 8px; 
  position: fixed;
  display: inline-block;
  left: 50px;
  top: 55px;
}

.offcanvas-body button {
  margin-bottom: 2.5vh;
}
/* Offcanvas end */

/* Tools Panel Start*/
.toolssection {
  position: fixed;
  z-index: 9;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 10vh;
  width: 16vw;
  left: 42vw;
  top: 73vh;
  margin-top: 100px;
  border-radius: 15px;
  background-color: #2C2C2C;
  padding: 4px;
  gap: 5px;
  box-shadow: 4px 4px 4px rgba(32, 32, 32, 0.418);
}

.usdztoolssection {
  width: 8vw;
  left: 45vw;
}

.toolsimage {
  height: 7.5vh;
  width: 4vw;
  background-color: #333333;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolssection img {
  position: relative;
  top: 0;
  left: 0;
  height: 4.5vh;
  width: 4.5vw;
}
/* Tools Panel End*/

/* Annotation Caret buttons Start*/
.annotation-buttons-group { 
  background-color: #333333;
  box-shadow: 2px 2px 2px rgba(32, 32, 32, 0.418);
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 10px;
  height: 10vh;
  width: 20vw;
  left: 55vw;
  top: 75vh;
  gap: 1vw ;
}

button.caret-left , button.caret-right  {
  z-index: 10;
  height: 50px;
  border: 0px;
}

.annotation-name {
  font-size: 1.6rem;
  color: aliceblue;
  /* margin-top: 8px; */
}
/* Annotation Caret buttons End */

/* Upload Model Panel */
.upload-model-panel {
  position: absolute;
  height: 20vh;
  width: 20vw;
  background-color: #2C2C2C;
  z-index: 2000;
}

.Panel_panel__jdFID {
  width: 25vw;
}

.Annotation_animations__VjUde {
  height: 46vh;
  overflow: scroll;
  overflow-x: hidden;
}

/* Annotation jsx  */

.annotationlabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}

.inputlabelcontainer {
  display: flex;
  justify-content: flex-end;
}

.importpanobutton {
    width: 50vw;
    left: 26vw;
    top: 40vh;
}

.importpanolabel {
    position: absolute;
    left: 38vw;
    top: 40vh;
    width: 25vw;
    height: 10vh;
}

.importpanolabel {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.importannotationbutton {
  top: 1vh;
  left: 12vw;
width: 25vw;
}

.checkbutton {
  background-image: url('/public/images/checked.png');
  background-repeat: no-repeat;
  height  : 30px;
  width: 35px;
  background-size: 90% 90%;
  filter: brightness(90%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}

.crossbutton {
  background-image: url('/public/images/cancel.png');
  background-repeat: no-repeat;
  height  : 30px;
  width: 35px;
  background-size: 90% 90%;
  filter: brightness(90%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}

.entrypanel {
  display: flex;
  align-items: center;
  justify-content: center ;
  z-index: 1000;
  background-color: white;
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.entrypaneltext {
  position: absolute;
  font-weight: 500;
  font-size: large;
  top: 33vh;
  word-spacing: 0.5vw;
  letter-spacing: 0.1vw;
}