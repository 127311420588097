.animations {
    margin-bottom: 35px;
    border-top: 1px solid #E3E3E3;
}

/* .shadowmainsections {
    display: flex;
    flex-direction: column;
} */

.textandcaret {
    margin-top: 2vh;
    display: flex;
    justify-content: center;
    gap: 13.3vw;
}

.animationstext {
    font-weight: 500;
    margin-left: 5%;
}


.animationspanel {
    width: 386px;
    height: 210px;
}

.recordanimationsbutton button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 20px;
    right: -20px;
    color: #0A6BC7;
    border-color: #E3E3E3;
}

.recordedvideos {
    position: relative;
    top: 70px;
    right: -20px;
}

.recordedvideosbutton button{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 90px;
    right: -20px;
    border-color: #E3E3E3;
}

.exportanimationsbutton {
    position: absolute;
    left: 5%;
    margin-top: 10%;
    bottom: 3vh;
}

