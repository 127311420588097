.toolssection {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 348px;
    height: 68px;
    top: 1032px;
    left: 315px;
    padding: 10px;
    gap: 12px;
    border-radius: 15px;
    opacity: 0px;
    background-color: #2C2C2C;
}

.toolssection img {
    position: relative;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
}

.toolsimage {
    height: 48px;
    width: 48px;
    background-color: #333333;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
